import React from 'react'
import { graphql, Link } from 'gatsby'
import {get, chunk} from 'lodash'
import Helmet from 'react-helmet'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import YotpoWidget from '../components/yotpo/widget'
import HeroImage from '../components/contentful/hero-image'
import { YOTPO_WIDGETS } from '../constants.js'
import Spinner from 'react-bootstrap/Spinner'
import { buildBreadcrumbsItem, collectionUrl, blogReviewsListUrl } from '../helpers'
import styles from '../scss/page.scss'

class ReviewsPage extends React.Component {
  
  render() {
    const title = 'Reviews | Honest Paws®'
    this.pageUrl = this.props.location.href
    const seo = {
      title: title,
      meta: {
        title: title,
        description: 'Read the amazing stories of how Honest Paws has affected other pet parents lives.'
      },
      children: [
        <link rel="canonical" href={ this.pageUrl } key='canonical-url' />
      ]
    }    
    const breadcrumbs = [
      buildBreadcrumbsItem('Reviews', this.props.location.href)
    ]
    return (
      <Layout containerClassName={`page page-reviews`} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden" location={this.props.location}>
        <SEO { ...seo } />
        <div className="page-header-top-placeholder"></div>
        <section className="page-title-and-summary page-header text-center px-4">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h1 className="mb-0">Honest Paws Reviews</h1>
              <div className="reviews-description mt-3">
                Learn more about why pet parents love our <a href="https://www.honestpaws.com/collections/cbd-oil-for-dogs" target="_top">CBD Oil for Dogs</a> and <a href="https://www.honestpaws.com/collections/cbd-dog-treats" target="_top">CBD Dog Treats</a>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="container page-content-container text-center">
            {/*<a href="https://www.honestpaws.com/reviews/happy-tails" target="_top" className="btn btn-success mb-5">View Customer Success Stories</a>*/}
            <YotpoWidget type={YOTPO_WIDGETS.SITE}>
              <Spinner animation="border" role="status" size='sm'><span className="sr-only">Loading...</span></Spinner>
            </YotpoWidget>
          </div>
        </section>
      </Layout>
    )
  }
}

export default ReviewsPage

export const ReviewsQuery = graphql`
  query ReviewsQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }      
  }
`